body,
html,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: 'Inter var', sans-serif;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}



canvas {
  margin: 0;
  padding: 0;
  touch-action: none;
}

canvas:active {
  cursor: grabbing;
}

.layer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

a {
  padding-right: 10px;
  cursor: pointer;
  pointer-events: all;
  color: white;
  text-decoration: none; /* no underline */
}

.content {
  border: 20px solid transparent;
  width: 710px;
  height: 350px;
  background: transparent;
  overflow: hidden;
  padding: 0;
}

.annotation {
  cursor: pointer;
  outline: none;
  border: none;
  font-size: 8px;
  font-weight: 300;
  background: black;
  color: #f0f0f0;
  padding: 2px 10px;
  border-radius: 20px;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}


.span {
  pointer-events: none;
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  position: relative;
  }

span::before {
  border-radius: 100px;
  position: absolute;
  
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all .3s ease-in-out 0s;
}

span::after {
  content: '';
  width: 30px; height: 30px;
  border-radius: 100%;
  border: 2px solid white;
  position: absolute;
  z-index: -1;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
}

@keyframes ring {
  0% {
    width: 10px;
    height: 10px;
    opacity: 1;
  }
  100% {
    width: 60px;
    height: 60px;
    opacity: 0;
  }
}